import React from 'react';
import About from '../../Components/About';
import IGFeed from '../../Components/IGFeed';
import Hero from '../../Components/Hero';
import Work from '../../Components/Work';

const HomePage = () => {
  return (
    <div className="homepage-app">
        <Hero />
        <About />
        <Work />
        <IGFeed />
    </div>
  )
}

export default HomePage