import React from 'react';
import Monstera from '../../Assets/Monstera-plant.jpg';

const HealthiePage = () => {
  return (
    <div style={{ backgroundImage:`url(${Monstera})`,backgroundRepeat:"no-repeat",backgroundSize:"1920px 1080px", 
    }}>
        <iframe className='HealthieHero'
        src="https://secure.gethealthie.com/appointments/embed_appt?dietitian_id=2308237&require_offering=true&offering_ids=[133572,135479,135480,135481,135485]&hide_embed_title=false&hide_package_images=false" title='Healthie Scheduler'></iframe>
  <br />
  <p> Booking Provided by <a className='footer-link' href="https://gethealthie.com">Healthie</a> </p>
    </div>
  )
}

export default HealthiePage;