import React from "react";
import { Link } from "react-router-dom";
import Logo from "../Assets/Logo.svg";
import { SiInstagram, SiGmail } from "react-icons/si";

const Footer = () => {
  return (
    <div className="footer-wrapper">
      <div className="footer-section-one">
        <div className="footer-logo-container">
          <img src={Logo} alt="Nutrition By Martha Logo" />
        </div>
        <div className="footer-icons">
          <Link to="https://www.instagram.com/nutrition_by_martha/">
          <SiInstagram /></Link>
          <Link to="mailto:mtriaynutrition@gmail.com">
          <SiGmail /></Link>
        </div>
      </div>
      <div className="footer-section-two">
        <div className="footer-section-columns">
          <a className="footer-link" href="/">Home</a>
          <a className="footer-link" href="/about">About</a>
          <a className="footer-link" href="/blog">Blog</a>
          <a className="footer-link" href="/contact">Contact</a>
          <a className="footer-link" href="https://secure.gethealthie.com/go/nutritionbymartha">Client Portal</a>
          <a className="footer-link" href="/schedule">Book An Appointment</a>
        </div>
        <div className="footer-section-columns">
          <a className="footer-link" href="mailto:mtriaynutrition@gmail.com">mtriaynutrition@gmail.com</a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
