import { useState, useEffect} from 'react'


const AboutPage = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const toRotate = [ "healthy", "realistic", "positive" ];
  const period = 2000;

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => { clearInterval(ticker) };
  }, [text])

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta(prevDelta => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex(prevIndex => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(500);
    } else {
      setIndex(prevIndex => prevIndex + 1);
    }
  }

  return (
    <div className='about-page-container'>
    <div className='about-page-top'>
    <h1>{`Empowering children and their families to develop`} <span className="txt-rotate" dataPeriod="1000" data-rotate='[ "healthy", "realistic", "positive" ]'><span className="wrap">{text}</span></span>{` relationships with food.`}</h1>

<p className='primary-text'>
I believe in the healing power of food and love. <br />

My journey started at the University of Nevada, Reno, where my original goal of pursuing medical school transformed into a passion for dietetics. <br />
I discovered the immense value in helping people heal through nutrition.<br />
One day I asked myself, "Does anyone have a healthy relationship with food?" <br />
This realization highlighted the fundamental importance of food beyond its nutritional value.
<br />
I recognized the challenges faced by under-served populations who struggle to access food, which led me to question why we recommend organic options when basic sustenance is a struggle for some. I firmly believe that food is for every body.
<br />
My desire to serve the pediatric population was sparked by Urban Roots, where I witnessed so many parents share a deep desire for their children to be healthy. I became determined to debunk the unknowns surrounding nutrition while providing reassurance to families. During Urban Roots’ summer camps, I noticed how fascinated children are by food -- Nutrition Week and Cooking Week were two of the most popular experiences for the campers. 
<br />
In my journey, I noticed a lack of folks that looked like me. ______________ (statistic here, maybe?)________ I realized the need to empower the Hispanic community.
</p>

<p className='primary-text'>
As a Registered Dietician, my services cater to a wide range of pediatric patients, including:
<br/>
<ul>
<li>those dealing with disordered eating</li>
<li>compulsive/emotional eating</li>
<li>failure to thrive</li>
<li>intuitive eating</li>
<li>food allergies and intolerances</li>
<li>vegetarian and vegan diets</li>
</ul>
<br/>
I understand that each patient has unique needs, which is why my focus is on equitable care, ensuring that every child receives the attention they deserve.
</p>

<p className='primary-text'>
I offer food education for parents, creating a guilt-free environment for learning. My approach is rooted in honesty -- giving you the advice you need to hear while also supporting you every step of the way. As both someone who is culturally sensitive and an LGBTQ+ ally, I will prioritize the needs of your child, providing the advice and guidance that they need to reach their health goals. </p>

<p className='primary-text'>
Throughout my journey, I’ve had the privilege of serving various populations, including both those at Urban Roots and from my previous job experiences. These experiences have shaped my values, which is why I am dedicated to providing honest, real, weight-inclusive, culturally sensitive, and child-centered care. </p>

<p className='primary-text'>
Thank you for considering me as your Registered Dietician in your child's health journey. I am here for you, bringing expertise and a genuine commitment to making a positive impact on your family's relationship with food. </p>
      
      </div>
    </div>
  )
}

export default AboutPage
