import React from 'react'
import Blog from '../../Components/Blog'

const BlogPage = () => {
  return (
    <div>
      <Blog />
    </div>
  )
}

export default BlogPage