/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import Logo from "../Assets/Logo.svg";
import { HiOutlineBars3 } from "react-icons/hi2";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import CommentRoundedIcon from "@mui/icons-material/CommentRounded";
import PhoneRoundedIcon from "@mui/icons-material/PhoneRounded";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

const Navbar = () => {
  const [openMenu, setOpenMenu] = useState(false);
  return (
    <nav className="sticky-nav">
      <div className="nav-logo-container">
          <img src={Logo} alt="Nutrition By Martha Logo" />
        </div>
      <div className="navbar-links-container">
        <a href="/">Home</a>
        <a href="/about">About</a>
        <a href="/blog">Blog</a>
        <a href="/contact">Contact</a>
        <a href="https://secure.gethealthie.com/go/nutritionbymartha">Client Portal</a>
        <a href="/schedule">
        <button className="primary-button">Schedule an Appointment</button></a>
      </div>
      <div className="navbar-menu-container">
        <HiOutlineBars3 onClick={() => setOpenMenu(true)} />
      </div>
      <Drawer open={openMenu} onClose={() => setOpenMenu(false)} anchor="right">
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={() => setOpenMenu(false)}
          onKeyDown={() => setOpenMenu(false)}
        >
          <List>
           
           <ListItem>
            <ListItemButton component="a" href="/">
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary="Home">
              </ListItemText>
            </ListItemButton>
           </ListItem>

           <ListItem>
            <ListItemButton component="a" href="/about">
              <ListItemIcon>
                <InfoIcon />
              </ListItemIcon>
              <ListItemText primary="About">
              </ListItemText>
            </ListItemButton>
           </ListItem>

           <ListItem>
            <ListItemButton component="a" href="/blog">
              <ListItemIcon>
                <CommentRoundedIcon />
              </ListItemIcon>
              <ListItemText primary="Blog">
              </ListItemText>
            </ListItemButton>
           </ListItem>

           <ListItem>
            <ListItemButton component="a" href="/contact">
              <ListItemIcon>
                <PhoneRoundedIcon />
              </ListItemIcon>
              <ListItemText primary="Contact">
              </ListItemText>
            </ListItemButton>
           </ListItem>

           <ListItem>
            <ListItemButton component="a" href="https://secure.gethealthie.com/go/nutritionbymartha">
              <ListItemIcon>
                <AccountCircleIcon />
              </ListItemIcon>
              <ListItemText primary="Client Portal">
              </ListItemText>
            </ListItemButton>
           </ListItem>

           <ListItem>
            <ListItemButton component="a" href="/schedule">
              <ListItemIcon>
                <CalendarMonthIcon />
              </ListItemIcon>
              <ListItemText primary="Schedule an Appointment">
              </ListItemText>
            </ListItemButton>
           </ListItem>

          </List>
          <Divider />
        </Box>
      </Drawer>
    </nav>
  );
};

export default Navbar;
