import React from "react";
import MarthaPNG from "../Assets/nutrition_by_martha.png" 
import BannerBackground from "../Assets/home-banner-background.png";
import BannerImage from "../Assets/MarthaBannerImage.png";
import { FiArrowRight } from "react-icons/fi";

const Home = () => {
  return (
    <div className="home-container">
      <div className="home-banner-container">
        <div className="home-bannerImage-container">
          <img src={BannerBackground} alt="" />
        </div>
        <div className="home-text-section">
          <h1 className="primary-heading">
            <img src={MarthaPNG} alt="" />
          </h1>
          <p className="primary-text">
          Hi! I'm Martha, a Pediatric Dietitian with a deep passion for promoting the health and well-being of children. I work closely with young patients and their families, providing personalized guidance and support. Whether its working with a 4 year old picky eater or a 16 year old on eating sufficiently in their day.
          </p>
          <a href="/about">
          <button className="secondary-button">
            Learn more about Me! <FiArrowRight />{" "}
          </button>
          </a>
        </div>
        <div className="home-image-section">
          <img src={BannerImage} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Home;
