import "./App.css";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import AboutPage from "./Pages/About/AboutPage";
import HealthiePage from "./Pages/Healthie/HealthiePage";
import {
  Routes,
  Route,
  Outlet,
  Link,
} from 'react-router-dom';
import HomePage from "./Pages/Home/HomePage";
import BlogPage from "./Pages/Blog/BlogPage";
import ContactPage from "./Pages/Contact/ContactPage";

export default function App() {
  return (
    <div className="App">
      <Navbar />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<HomeFunction />} />
          <Route path="about" element={<AboutFunction />} />
          <Route path="blog" element={<BlogFunction />} />
          <Route path="contact" element={<ContactFunction />} />
          <Route path="schedule" element={<HealthieFunction />} />
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
    </div>
  );
}

function Layout() {
  return (
    <div>
      {/* A "layout route" is a good place to put markup you want to
          share across all the pages on your site, like navigation. */}
      <Outlet />
      <Footer />
    </div>
  );
}

function HomeFunction() {
  return (
    <div>
    <HomePage />
    </div>
  );
}

function AboutFunction() {
  return (
    <div>
      <AboutPage />
    </div>
  )
}

function BlogFunction() {
  return (
    <div>
      <BlogPage />
    </div>
  )
}

function ContactFunction() {
  return (
    <div>
      <ContactPage />
    </div>
  )
}

function HealthieFunction() {
  return (
    <div>
      <HealthiePage />
    </div>
  )
}

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}