import React from "react";
import Image1 from "../Assets/image1.png";
import Image2 from "../Assets/image2.png";
import Image3 from "../Assets/image3.png";

const Work = () => {
  const workInfoData = [
    {
      image: Image1,
      title: "Realistic planning with you in mind",
      text: "I tailor my recommendations to each individual as everyone’s dietary needs and preferences vary. Through our collaborative work, I will gain a deeper understanding of your family’s habits to provide more personalized and effective guidance.",
    },
    {
      image: Image2,
      title: "Developing healthy habits",
      text: "Relationship with food, regardless what are you are is my top priority. This is helpful for picky eaters eating only one food or disordered eating for those that don’t eat much in the day or will not try new foods.",
    },
    {
      image: Image3,
      title: "From the comfort of home",
      text: "I know how hard it is to go on an outing with a little one, especially if there are more than one. Which is why my services are conducted 100% virtually.",
    },
  ];
  return (
    <div className="work-section-wrapper">
      <div className="work-section-top">
        <p className="primary-subheading">The Ways of Martha</p>
        <h1 className="primary-heading">My Method of Guidance</h1>
        <p className="primary-text">
          In your culture you eat rice at every meal? Great! In your family you don't have sit down meals together? Cool, lets talk about it. I am not here to judge, but to help anyone with any food struggles their family may be having, including yours!
        </p>
      </div>
      <div className="work-section-bottom">
        {workInfoData.map((data) => (
          <div className="work-section-info" key={data.title}>
            <div className="info-boxes-img-container">
              <img src={data.image} alt="" />
            </div>
            <h2>{data.title}</h2>
            <p>{data.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Work;
