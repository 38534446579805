import React from "react";

const IGFeed = () => {
  return (
    <div className="contact-page-wrapper">
              <div>
              <iframe className="ig-feed" title='@nutritionbymartha on Instagram' src='https://www.juicer.io/api/feeds/nutrition_by_martha/iframe' frameborder='0'></iframe>
              {/* <script className="ig-feed" type="text/javascript" src="https://www.juicer.io/embed/nutrition_by_martha/embed-code.js?per=15" async defer></script> */}
        </div>
    </div>
  );
};

export default IGFeed;