import React from "react";
import AboutBackground from "../Assets/about-background.png";
import AboutBackgroundImage from "../Assets/about-background-image.png";

const About = () => {
  return (
    <div className="about-section-container">
      <div className="about-background-image-container">
        <img src={AboutBackground} alt="" />
      </div>
      <div className="about-section-image-container">
        <img src={AboutBackgroundImage} alt="" />
      </div>
      <div className="about-section-text-container">
        <p className="primary-subheading">A Bit About Me</p>
        <h1 className="primary-heading">
          Food Is An Important Part Of Life
        </h1>
        <p className="primary-text">
        As a Mexican-American I know how important food is in any culture. Food brings us together but when it is demonized it can also break up apart. I accept that we all have different relationships and experiences with food. It is my job to meet you where you are and help you and your thrive in your own way.
        </p>
        <div className="about-buttons-container">
          <button className="secondary-button"> <a href="/about">Learn More</a></button>
        </div>
      </div>
    </div>
  );
};

export default About;
